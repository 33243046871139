<template>
  <div class="detail-page">
    <van-swipe class="swiper">
      <van-swipe-item v-for="(image, index) in info.image" :key="`image-${image}-${index}`">
        <van-image width="100%" height="100%" :src="image.url" lazy-load />
      </van-swipe-item>
    </van-swipe>
    <div class="content">
      <div class="title">{{ info.title }}</div>
      <div class="title-eng" v-if="info.description">{{ info.description }}</div>
      <van-cell-group class="info">
        <van-cell title="酒店地址" :label="info.address" />
      </van-cell-group>
      <div class="detail">
        <div v-html="info.hotelDetail"></div>
        <van-divider />
        <div class="product-detail">
          <template v-for="item of info.product">
            <div class="product-detail-item" :key="item.id">
              <div class="product-info">
                <span class="title">{{ item.title }}</span><span class="price">{{ item.price }}元{{ item.unit && '/' + item.unit }}</span>
              </div>
              <div v-html="item.content"></div>
              <van-divider />
            </div>
          </template>
        </div>
      </div>
    </div>
    <van-goods-action>
      <van-goods-action-button
        type="danger"
        text="立即预订"
        @click="handleClickBook"
      />
    </van-goods-action>
    <van-sku
      v-model="sku.isShow"
      :sku="sku.skuData"
      :goods="sku.goods"
      :show-add-cart-btn="false"
      :hide-stock="true"
      @buy-clicked="onBuyClicked"
    >
      <template #sku-stepper>&nbsp;</template>
    </van-sku>
  </div>
</template>

<script>
import { getHotelInfo } from '../api'
export default {
  name: 'detail-page',
  data() {
    return {
      info: {
        hotelId: '',
        hotelCatid: '',
        title: '',
        description: '',
        address: '',
        phone: '',
        hotelDetail: '',
        image: [],
        content: '',
        product: []
      },
      sku: {
        isShow: false,
        goods: {
          picture: ''
        },
        skuData: {
          tree: [
            {
              k: '类型',
              k_s: 'type',
              v: [],
              largeImageMode: true
            }
          ],
          list: [

          ],
          price: '-'
        },
      }
    }
  },
  methods: {
    handleClickBook() {
      this.sku.isShow = true
    },
    handlePhone() {
      location.href = `tel:${this.info.phone}`
    },
    onBuyClicked(product) {
      const { catid, id } = product.selectedSkuComb
      this.$router.push({ name: 'bookconfirm', query: { hcatid: this.info.hotelCatid, hid: this.info.hotelId, pcatid: catid, pid: id } })
    },
    init() {
      const { catid = '', id = '' } = this.$route.query
      getHotelInfo({ catid, id }).then(res => {
        const { title, hotelimage = [], product = [], content, phone, address, description, catid, id } = res.data
        this.info.hotelCatid = catid
        this.info.hotelId = id
        this.info.title = title
        this.info.image = hotelimage
        this.info.hotelDetail = content
        this.info.phone = phone
        this.info.address = address
        this.info.description = description
        this.info.product = product
        // 设置产品信息
        this.sku.goods.picture = (hotelimage[0] && hotelimage[0].url) || ''
        console.log("product: ", product)
        product.forEach(item => {
          this.sku.skuData.tree[0].v.push({
            id: item.id,
            name: item.title,
            imgUrl: item.thumb,
            previewImgUrl: item.thumb
          })
          this.sku.skuData.list.push({
            id: item.id,
            type: item.id,
            catid: item.catid,
            price: item.price * 100,
            stock_num: 100
          },)
        })
      })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.detail-page {
  padding-top: 48px;

  .swiper {
    width: 375px;
    height: 300px;
  }

  .content {
    padding: 10px;

    .title {
      font-size: 24px;
    }

    .title-eng {
      font-size: 18px;
      color: #aaa;
    }

    .info {
      margin-top: 10px;

      .phone-icon {
        position: relative;
        top: 3px;
      }
    }
  }

  .product-info {
    font-weight: bold;

    .title {
      font-size: 20px;
    }
    .price {
      margin-left: 5px;
      font-size: 16px;
      color: #ff5741;
      font-family: Avenir-Heavy, PingFang SC, Helvetica Neue, Arial, sans-serif;
    }
  }
}
</style>

<style>
p {
  margin: 0 !important;
}
</style>